<template>
  <div :class="['search-filter', 'search-filter-text', { 'is-expanded': expanded }]">
    <div class="filter-header">
      <b-button class="filter-expander" variant="link" block @click="toggleExpanded()">
        <b-icon icon="arrow-dropdown"/>{{ filter.displayName }} <span v-if="filterSetCount" class="filter-set-count">{{ filterSetCount }}</span>
      </b-button>
    </div>

    <transition-expand>
      <div v-if="expanded" class="filter-body">
        <div class="filter-controls">
          <div
            v-for="control in filter.controls"
            :key="control.id"
            v-show="!control.hide"
            class="controls-control"
          >
            <control-input
              :id="control.id"
              :value="control.value"
              size="sm"
              ref="control"
              @input="$value => updateControlDelayed(control, $value)"
              @keydown.native.esc="resetControls"
            />

            <b-button variant="link" size="sm" class="control-reset" :disabled="noneFilterSet" @click="resetControls"><b-icon icon="close" scale="0.8"/></b-button>
          </div>
        </div>
      </div>
    </transition-expand>
  </div>
</template>

<script>
import MixinFilterBase from '@/components/search/MixinFilterBase'

export default {
  name: 'Search.FilterText',
  mixins: [MixinFilterBase],
  data () {
    return {
      delay: {
        timeout: null,
        ms: 250
      }
    }
  },
  methods: {
    updateControlDelayed (control, value) {
      if (this.delay.timeout) clearTimeout(this.delay.timeout)

      this.delay.timeout = setTimeout(() => {
        this.updateControl(control, value)
      }, this.delay.ms)
    }
  },
  created () {
    this.$on('filter:expanding', () => {
      this.$nextTick(() => {
        this.$refs.control[0].$el.querySelector('input').focus()
      })
    })
  }
}
</script>

<style lang="scss">
$filtertext-controls-control-input-padding-x: $input-padding-x-sm !default;
$filtertext-controls-control-input-bg: $white !default;

$filtertext-control-reset-color: $danger !default;
$filtertext-control-reset-color-disabled: $btn-link-disabled-color !default;

$filtertext-mobile-breakpoint: $mobile-breakpoint !default;

$filtertext-mobile-controls-control-input-bg: $gray-100 !default;

.search-filter-text {
  .filter-controls {
    .controls-control {
      display: block;
      position: relative;

      .control-input {
        position: relative;
        z-index: 1;

        input {
          padding-left: $filtertext-controls-control-input-padding-x;
          padding-right: $filtertext-controls-control-input-padding-x;
          background-color: $filtertext-controls-control-input-bg;
          border: 0 none;
        }
      }

      .control-reset {
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        right: $filtertext-controls-control-input-padding-x;
        padding: 0;
        width: 1.5em;
        color: $filtertext-control-reset-color;
        box-shadow: none;
        z-index: 2;

        &.disabled {
          color: $filtertext-control-reset-color-disabled;
        }
      }
    }
  }

  @include media-breakpoint-down($filtertext-mobile-breakpoint) {
    .filter-controls {
      .controls-control {
        .control-input {
          input {
            background-color: $filtertext-mobile-controls-control-input-bg;
          }
        }
      }
    }
  }
}
</style>
