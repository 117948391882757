<template>
  <div class="search">
    <c-grid-aside :toggle-text="$T('filterTitle')">
      <template v-slot:aside="">
        <div class="search-filters">
          <div class="filter-meta">
            <b-form-row class="justify-content-between align-items-center">
              <b-col cols="auto">
                <strong>{{ $T('filterTitle') }}</strong>
              </b-col>

              <b-col cols="auto">
                <b-button variant="link" @click="resetFilters">{{ $T('filterResetAll') }}</b-button>
              </b-col>
            </b-form-row>
          </div>

          <component
            v-for="filter in filters"
            :key="filter.field"
            :is="filter.component"
            v-bind:filter="filter"
            :translations="TRANSLATIONS.filter"
            @filter:change="setFilters"
          />
        </div>
      </template>

      <template #content>
        <div class="search-results">
          <div v-if="!isInitialized" class="loader-wrapper empty">
            <c-loader/>
          </div>

          <template v-else>
            <div class="results-header">
              <b-row align-h="between" align-v="center">
                <b-col cols="auto">
                  <strong>{{ $T(`totalCount${Math.min(statistics.total.items, 2)}`, { count: statistics.total.items }) }}</strong>
                </b-col>

                <b-col cols="auto">
                  <b-dropdown v-if="activeSort" class="dropdown-filter" right>
                    <template #button-content>
                      <div>
                        {{ activeSort.displayName }}
                        <b-icon class="text-primary" icon="arrow-dropdown"/>
                      </div>
                    </template>
                    <b-dropdown-item
                      v-for="sort in sorts"
                      :key="sort.id"
                      :active="sort.isActive"
                      @click="setActiveSort(sort.id)"
                    >
                      {{ sort.displayName }}
                    </b-dropdown-item>
                  </b-dropdown>
                </b-col>
                 <b-col cols="auto" v-if="$slots['resultactions']">
                  <slot name="resultactions"/>
                 </b-col>
              </b-row>
            </div>

            <div class="results-body">
              <component
                :is="resultsComponent"
                :resultType="resultType"
                :results="results"
                :showPackshotBasketButton="showPackshotBasketButton"
                :translations="TRANSLATIONS.results"
              >
                <template #resultadditionalinfo="slotProps">
                  <slot name="resultitemadditionalinfo" v-bind="{ resultItem: slotProps.resultItem }"/>
                </template>
              </component>
            </div>

            <div class="results-footer">
              <b-button v-if="statistics.page.number < statistics.total.pages" class="button-loadmore" variant="link" @click="loadMore">{{ $T('loadMore') }}</b-button>
            </div>
          </template>
        </div>
      </template>
    </c-grid-aside>
  </div>
</template>

<script>
import { SEARCH_TYPE_OPTIONS } from '@/assets/js/config/server'

import FilterText from '@/components/search/FilterText'
import FilterRange from '@/components/search/FilterRange'
import FilterMultiselect from '@/components/search/FilterMultiselect'
import FilterDaterange from '@/components/search/FilterDaterange'
import FilterUnknown from '@/components/search/FilterUnknown'
import ResultstypeProduct from '@/components/search/ResultstypeProduct'
import ResultstypeDocument from '@/components/search/ResultstypeDocument'
import ResultstypeUnknown from '@/components/search/ResultstypeUnknown'

const SEARCH_TYPE_OPTIONS_KEYS = Object.keys(SEARCH_TYPE_OPTIONS)
const SEARCH_TYPE_OPTION_DEFAULT_KEY = SEARCH_TYPE_OPTIONS_KEYS.find(oKey => SEARCH_TYPE_OPTIONS[oKey].isDefault) || SEARCH_TYPE_OPTIONS_KEYS[0]

export default {
  name: 'Search',
  components: {
    FilterText,
    FilterRange,
    FilterMultiselect,
    FilterDaterange,
    FilterUnknown,
    ResultstypeProduct,
    ResultstypeDocument,
    ResultstypeUnknown
  },
  props: {
    searchType: {
      type: String,
      default: SEARCH_TYPE_OPTION_DEFAULT_KEY
    },
    initialFilters: {
      type: Array,
      default: () => ([])
    },
    showPackshotBasketButton: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isInitialized: false,
      resultType: SEARCH_TYPE_OPTIONS[this.searchType].resultType,
      resultsComponent: `resultstype-${SEARCH_TYPE_OPTIONS[this.searchType].resultType || 'unknown'}`
    }
  },
  computed: {
    is () {
      return this.$store.getters['search/is']
    },
    statistics () {
      return this.$store.getters['search/statistics']
    },
    filters () {
      return this.$store.getters['search/filters']
    },
    sorts () {
      return this.$store.getters['search/sorts']
    },
    activeSort () {
      return this.$store.getters['search/getActiveSort'] || []
    },
    results () {
      return this.$store.getters['search/get']
    }
  },
  methods: {
    searchByFilters () {
      this.$store.dispatch('search/searchByFilters')
    },
    setFilters () {
      this.$store.dispatch('search/setFilters')
    },
    setActiveSort (sortId) {
      this.$store.dispatch('search/setActiveSort', { sortId })
    },
    loadMore () {
      this.$store.dispatch('search/getNextSearchPage')
    },
    resetFilters () {
      this.$store.dispatch('search/resetFilters')
    }
  },
  created () {
    this.$store.dispatch('search/init', {
      searchType: this.searchType,
      filters: this.initialFilters
    })
      .finally(() => {
        this.isInitialized = true
      })
  }
}
</script>

<style lang="scss">
$search-filters-meta-padding: 0 0 $spacer !default;
$search-filters-meta-button-font-size: $font-size-sm !default;

$search-results-header-gap: $spacer !default;

$search-results-body-product-gap: $grid-gutter-width !default;

$search-results-footer-gap: $search-results-header-gap !default;

.search {
  .search-filters {
    .filter-meta {
      padding: $search-filters-meta-padding;

      .btn {
        padding: 0;
        font-size: $search-filters-meta-button-font-size;
      }
    }
  }

  .search-results {
    .results-header {
      margin-bottom: $search-results-header-gap;
    }

    .results-body {
      .row {
        margin-top: $search-results-body-product-gap * -0.5;
        margin-bottom: $search-results-body-product-gap * -0.5;

        > * {
          margin-top: $search-results-body-product-gap * 0.5;
          margin-bottom: $search-results-body-product-gap * 0.5;
        }
      }
    }

    .results-footer {
      display: flex;
      justify-content: flex-end;
      margin-top: $search-results-footer-gap;

      .button-loadmore {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
}
</style>
