<template>
  <div class="search-result-documents">
    <div class="document" v-for="result in results" :key="result.id">
      <div class="document-properties">
        <div class="document-property property-name"><div class="property-label sr-only">{{ $T('LabelName') }}</div> {{ result.name }}</div>
        <hr class="my-1" v-if="result.type || result.topic">

        <div class="properties-gapped">
          <div class="document-property property-type">
            <div class="property-label" v-if="result.type">{{ $T('LabelType') }}</div>
            <div class="badge-group">
              <b-badge variant="info" v-for="type in result.type" :key="type">{{ type }}</b-badge>
            </div>
          </div>

          <div class="document-property property-topic">
            <div class="property-label" v-if="result.topic">{{ $T('LabelTopic') }}</div>
            <div class="badge-group">
              <b-badge variant="info" v-for="topic in result.topic" :key="topic">{{ topic }}</b-badge>
            </div>
          </div>
        </div>
      </div>

      <div class="document-actions">
        <div class="actions-inner">
          <b-button :href="result.downloadUrl" variant="document" size="sm" target="_blank">{{ $T('ButtonDownload') }}</b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Search.ResultstypeDocument',
  props: {
    results: {
      type: Array,
      default: () => ([])
    }
  }
}
</script>

<style lang="scss">
$searchresultdocuments-document-gap: $spacer * 0.5 !default;

$searchresultdocuments-document-padding: ($component-padding-y * 0.25) $component-padding-x !default;
$searchresultdocuments-document-bg: $white !default;
$searchresultdocuments-document-border-radius: $leaf-border-radius-sm !default;

$searchresultdocuments-document-property-spacer: $spacer * 0.5 !default;
$searchresultdocuments-document-property-label-font-size: 11px !default;
$searchresultdocuments-document-property-label-color: $gray-500 !default;

$searchresultdocuments-document-actions-gap: $spacer !default;
$searchresultdocuments-document-actions-button-gap: $spacer * 0.5 !default;

$searchresultdocuments-document-actions-button-hover-animation: h-bounce 400ms ease-out 1 !default;

.search-result-documents {
  .document {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: $searchresultdocuments-document-padding;
    @include make-leaf($searchresultdocuments-document-border-radius);
    background-color: $searchresultdocuments-document-bg;

    + .document {
      margin-top: $searchresultdocuments-document-gap;
    }

    .document-properties {
      flex: 0 1 100%;

      .properties-gapped {
        display: flex;
        margin: $searchresultdocuments-document-property-spacer * -0.5;

        .document-property {
          flex: 0 1 100%;
          margin: $searchresultdocuments-document-property-spacer * 0.5;
        }
      }

      .document-property {
        .property-label {
          font-size: $searchresultdocuments-document-property-label-font-size;
          color: $searchresultdocuments-document-property-label-color;
        }
      }
    }

    .document-actions {
      margin-left: $searchresultdocuments-document-actions-gap;

      .actions-inner {
        margin: $searchresultdocuments-document-actions-button-gap * -0.5;

        .btn {
          margin: $searchresultdocuments-document-actions-button-gap * 0.5;
          white-space: nowrap;
        }
      }
    }

    &:hover {
      .document-actions {
        .actions-inner {
          .btn {
            animation: $searchresultdocuments-document-actions-button-hover-animation;
          }
        }
      }
    }
  }
}
</style>
