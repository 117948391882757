<template>
  <div :class="['search-filter', 'filter-range', `type-${filter.type}`, { 'is-expanded': expanded }]" v-if="slider.min !== slider.max">
   <div class="filter-header">
      <b-button class="filter-expander" variant="link" block @click="toggleExpanded()">
        <b-icon icon="arrow-dropdown"/>{{ filter.displayName }} <span v-if="filterSetCount" class="filter-set-count">{{ filterSetCount }}</span>
      </b-button>

      <b-button v-if="expanded" class="filter-search-expander" variant="link" @click="toggleSearchExpanded()"><b-icon icon="search"/></b-button>
    </div>

    <transition-expand n>
      <div v-if="expanded" class="filter-content">
          <div class="filter-controls">
            <div
              v-for="control in controls"
              :key="control.id"
              class="filter-control"
            >
              <b-input-group size="xs" :append="control.unit">
                <b-form-input
                  :id="control.id"
                  v-model="control.value"
                  lazyFormatter
                  @keydown="$event => controlInputManager($event, control)"
                  @change="controlChangeManager(control)"
                />
              </b-input-group>
            </div>
          </div>
          <div class="filter-slider">
          <MultiRangeSlider
            :min="slider.min"
            :max="slider.max"
            :step="slider.step"
            :minValue="slider.minValue"
            :maxValue="slider.maxValue"
            :preventWheel="true"
            :ruler="false"
            baseClassName="multi-range-slider-bar-only"
            @input="sliderChangeManager"
            />
          </div>
      </div>
    </transition-expand>
  </div>
</template>

<script>
import MixinFilterBase from '@/components/search/MixinFilterBase'
import MixinFilterSearch from '@/components/search/MixinFilterSearch'

import { copy } from '@/assets/js/helper/array'

import MultiRangeSlider from 'multi-range-slider-vue'
import 'multi-range-slider-vue/MultiRangeSliderBlack.css'
import 'multi-range-slider-vue/MultiRangeSliderBarOnly.css'

export default {
  name: 'SearchFilterRange',
  mixins: [MixinFilterBase, MixinFilterSearch],
  components: {
    MultiRangeSlider
  },
  data () {
    const controlsClone = copy(this.filter.controls || [])
    return {
      controls: controlsClone,
      slider: this.getSliderOptions(controlsClone)
    }
  },
  computed: {
    numberSeparators () {
      return this.$store.getters['gui/getNumber']
    }
  },
  methods: {
    getSliderOptions (controls = []) {
      const min = Math.round(controls[0].minValue)
      const max = Math.round(controls[1].maxValue)
      return {
        minValue: Math.round(controls[0].value),
        maxValue: Math.round(controls[1].value),
        min: min,
        max: max,
        step: 1
      }
    },
    controlInputManager (event, control = {}) {
      // prevent non allowed events
      if (!/(\d|ArrowLeft|ArrowRight|Backspace|Delete)/.test(event.key) && !(event.ctrlKey && event.key === 'a')) {
        event.preventDefault()
      }
    },
    controlChangeManager (control = {}) {
      const numberValue = Number(control.value === '' ? control.baseValue : String(control.value).replace(new RegExp(this.numberSeparators.thousandSeparator, 'g'), '').replace(this.numberSeparators.decimalSeparator, '.'))
      this.slider[control.key] = numberValue

      this.emitFilterChange()
    },
    sliderChangeManager (value) {
      if (value.min !== value.max) {
        this.controls[0].value = value.minValue
        this.controls[1].value = value.maxValue

        this.emitFilterChange()
      }
    },
    emitFilterChange () {
      this.controls
        .forEach((c, cIndex) => {
          this.filter.controls[cIndex].value = c.value
        })
      this.$emit('filter:change', this.filter)
    }
  },
  watch: {
    '$props.filter': {
      deep: true,
      handler (filter = {}) {
        const controlsClone = copy(this.filter.controls || [])

        this.slider = this.getSliderOptions(controlsClone)
        this.controls = controlsClone
      }
    }
  }
}
</script>

<style lang="scss">
.search-filter.filter-range {
  .filter-content {
    .filter-controls {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      .filter-control {
        .form-control {
          padding-left: 0.5rem;
          padding-right: 0.5rem;
        }

        + .filter-control {
          margin-left: $form-grid-gutter-width;
        }
      }
    }

    .filter-slider {
      .multi-range-slider-bar-only {
          .caption{
            display:none;
          }

          .bar-left,
          .bar-right{
            box-shadow:inset 0px 0px 2px black;
          }

          .bar-inner{
            background-color:$primary;
            box-shadow:none;
          }
          .thumb {
            &:before{
              box-shadow:none;
            }
          }
      }
    }
  }
}
</style>
