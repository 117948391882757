<template>
  <div>
    <b-alert :show="show" variant="danger">
      <h5>Unknown SearchResultstype "{{ resultType }}"</h5>
      <pre class="text-inherit">{{ results }}</pre>
    </b-alert>
  </div>
</template>

<script>
export default {
  name: 'Search.ResultstypeUnkonwn',
  props: {
    resultType: {
      type: String
    },
    results: {
      type: Array,
      default: () => ([])
    }
  },
  data () {
    return {
      show: process.env.NODE_ENV === 'development'
    }
  }
}
</script>

<style lang="scss"></style>
