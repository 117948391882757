<template>
  <div class="search-result-products">
    <b-row :cols="contentCols.xs" :cols-sm="contentCols.sm" :cols-md="contentCols.md" :cols-lg="contentCols.lg" :cols-xl="contentCols.xl">
      <b-col v-for="result in results" :key="result.id">
        <c-teaser
          :noImg="result.noImg"
          :tagText="result.event.type"
          :tagVariant="result.event.typeVariant"
          :languageText="result.event.language ? $T(`Language${result.event.language}`) : null"
          :stateText="result.event.state"
          :stateVariant="result.event.stateVariant"

          :href="result.href"

          :imgType="result.imgType"
          :imgSrc="result.imgSrc"
          :imgAlt="result.imgAlt"
        >
          <h3>{{ result.title }}</h3>
          <p v-if="result.product.portioning">{{ result.product.portioning }}</p>
          <p v-if="result.text">{{ result.text }}</p>
          <slot name="resultadditionalinfo" v-bind="{ resultItem: result }"/>

          <p v-if="result.event.date && result.event.location">
            <strong><timeago :datetime="result.event.date" :converterOptions="{ format: 'P', forceDateFormat: true }"/></strong>, {{ result.event.location }}
          </p>

          <template v-if="result.alternativeEvents.length > 0">
            <p v-if="!result.showAlternativeEvents">
              <b-link @click.stop="result.showAlternativeEvents = true">{{ $T('AlternativeEventsButton') }}</b-link>
            </p>

            <template v-else>
              <p v-for="event in result.alternativeEvents" :key="event.id">
                <a :href="event.url"><strong><timeago :datetime="event.date" :converterOptions="{ format: 'P', forceDateFormat: true }"/></strong>, {{ event.location }}</a>
              </p>
            </template>
          </template>
          <template v-if="showPackshotBasketButton">
            <c-packshot-basket-button :productId="result.event.id" :basketButtonText="$T('PackshotBasketButtonText')" :basketButtonRemoveText="$T('PackshotBasketRemoveButtonText')"/>
          </template>
        </c-teaser>
      </b-col>
    </b-row>

  </div>
</template>

<script>
import { ASIDE_COLUMN_WIDTHS } from '@/assets/js/config/client'

export default {
  name: 'Search.ResultstypeProduct',
  props: {
    resultType: {
      type: String
    },
    results: {
      type: Array,
      default: () => ([])
    },
    showPackshotBasketButton: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      contentCols: ASIDE_COLUMN_WIDTHS
    }
  }
}
</script>

<style lang="scss">
.search-result-products {}
</style>
